<template>
  <div-wrapper>
    <div-wrapper className="modal-overlaybg open"></div-wrapper>
    <div-wrapper
      id="confirm-modal"
      className="modal_pageview slideup modelslideup"
    >
      <div-wrapper className="confirm-box">
        <slot name="body"></slot>
        <div-wrapper className="confirm-bot">
          <span class="tab-item" @click="$emit('action', 'no')">
            {{ $t('_no') }}
          </span>
          <span class="tab-item" @click="$emit('action', 'yes')">
            {{ btnTxt }}
          </span>
        </div-wrapper>
      </div-wrapper>
    </div-wrapper>
  </div-wrapper>
</template>
<script>
import DivWrapper from '@/components/form/DivWrapper';
export default {
  components: {
    DivWrapper,
  },
  props: {
    btnTxt: {
      required: true,
      type: String,
      default: 'Yes',
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/_modal-box.scss';
</style>
